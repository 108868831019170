<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-60 md-small-size-90 md-xsmall-size-100 md-medium-size-80 mx-auto"
          >
            <registration-card header-color="info">
              <h4 slot="title" class="card-title">Reset Password</h4>
              <p slot="description" class="description">Reset password</p>
              
              <md-field :class="getValidationClass('password')" reqired=true class="md-form-group" slot="inputs">
                <md-icon>lock_outline</md-icon>
                <label>Password...</label>
                <md-input type="password" v-model="form.password"></md-input>   
                <span class="md-error" v-if="!$v.form.password.minlength">Must be at least 6 characters</span>
              </md-field>
            
              <md-field :class="getValidationClass('passwordRepeat')" reqired=true class="md-form-group" slot="inputs">
                <md-icon>lock_outline</md-icon>
                <label>Repeat password</label>
                <md-input type="password" v-model="form.passwordRepeat"></md-input>
                <span class="md-error" v-if="!$v.form.passwordRepeat.equals">Must be the same password</span>
              </md-field>
              <md-button @click="change()" type="submit"  slot="footer" class="md-simple md-success md-lg">change</md-button>
            </registration-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RegistrationCard } from "@/components";
import { AjaxCalls } from "@/mixins";
import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    maxLength,
    sameAs
  } from 'vuelidate/lib/validators'

const equalsPassword =  (value) => value === this.form.password;

export default {
  components: {
    RegistrationCard
  },
  bodyClass: "forgot-page",
  data() {
    return {
      token: this.$route.params.token,
      email : this.$route.params.email,
      form: {
        password: "",
        passwordRepeat: ""
      }
    };
  },
  validations: {
      form: {
        password: {
          required,
          minLength: minLength(6)
        },
        passwordRepeat: {
          required,
          sameAsPassword: sameAs(function () {
            return this.form.password;
          })         
        }
  }
  },
  mixins: [
    AjaxCalls,
    validationMixin],
  methods: {
    change() {
      this.$v.$touch();
      if(!this.$v.$invalid){
        this.changePassword(this.email, this.token, this.form.password)
        .then((response) => {
          this.$router.replace({ name: "login" });
        }).catch((error)=>{
          console.log(error);
          alert(error);
        });
      } else {

      }
    },
    getValidationClass (fieldName) {
        const field = this.$v.form[fieldName]

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      }
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    }
  }
};
</script>

<style lang="css">
  .md-card-content .md-field .md-error {
    bottom: -5px;
  }
</style>
