<template>
  <div class="wrapper">
    <div class="page-header header-filter" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="brand">
            <h3>Profile</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="main main-raised">
      <div class="section section-basic">
        <div class="container">
          <div class="md-layout">
            <div class="wrapper">
              <div id="nav-tabs">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <nav-tabs-card no-label>
                      <template slot="content">
                        <md-tabs
                          md-sync-route
                          :md-active-tab="activeTab"
                          class="md-primary"
                          md-alignment="centered"
                        >
                          <md-tab
                            id="settings"
                            md-label="Settings"
                            md-icon="build"
                          >
                            <md-field class="md-form-group">
                              <md-icon>paid</md-icon>
                              <label>Account type</label>
                              <md-input
                                disabled
                                v-model="user.accountType"
                              ></md-input>
                            </md-field>
                            <md-field class="md-form-group">
                              <md-icon>email</md-icon>
                              <label>Email</label>
                              <md-input
                                disabled
                                v-model="user.email"
                              ></md-input>
                            </md-field>
                            <div class="inline-radio-group">
                              <md-icon>device_thermostat</md-icon>
                              <label>Unit</label>
                              <md-radio
                                v-model="settings.imperial"
                                :value="false"
                                >Celsius</md-radio
                              >
                              <md-radio
                                v-model="settings.imperial"
                                :value="true"
                                >Fahrenheit</md-radio
                              >
                            </div>
                            <md-snackbar
                              :md-position="'center'"
                              :md-duration="2000"
                              :md-active.sync="showSavebar"
                              md-persistent
                            >
                              <span>Settings successfully saved</span>
                            </md-snackbar>
                            <md-button
                              :disabled="saving"
                              @click="saveSettings"
                              class="md-simple md-success md-lg"
                              >save</md-button
                            >
                          </md-tab>
                        </md-tabs>
                      </template>
                    </nav-tabs-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AjaxCalls } from "@/mixins";
import { NavTabsCard } from "@/components";
export default {
  bodyClass: "detail-page",
  components: {
    NavTabsCard,
  },
  mixins: [AjaxCalls],
  beforeMount() {
    var authInfoData = this.getUser();
    this.user.email = authInfoData.sub;
    this.user.accountType = this.isPremium()
      ? "premium account"
      : "free account";
      this.getUserSettings().then((resp) => {
        this.settings = resp.data;
      })
  },
  methods: {},
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg"),
    },
  },
  data() {
    return {
      user: {},
      settings: {},
      saving: false,
      showSavebar: false,
      activeTab: null,
    };
  },
  methods: {
    saveSettings() {
      this.saving = true;
      this.updateUserSettings(this.settings)
        .then((resp) => {
          this.showSavebar = true;
          this.saving = false;
        })
        .catch((error) => {
          alert(error);
          this.saving = false;
        });
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.brand {
  color: #ffffff;
}
.page-header {
  height: 30vh;
}
.inline-radio-group {
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px dotted #d2d2d2;

  .md-radio {
    margin-left: 12px;
    .md-radio-label {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }

  .md-radio-label {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .md-icon {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  label {
    left: 36px;
    font-size: 0.6875rem;
    color: #aaaaaa;
    line-height: 0.6;
    font-weight: 400;
    position: absolute;
  }
}
</style>