import axios from "axios";

const AjaxCalls = {
  data: function () {
    return {
      _user: null
    }
  },
  methods: {
    getSamples() {
      return axios.get("/data/samples.json");
    },
    getMeasurements(deviceId, timeFrame) {
      let info = this.getAuthInfo();
      return axios.get("/api/measurement/" + deviceId + "/" + timeFrame, { headers: { "Authorization": info.authorization } });
    },
    getAuthInfo() {
      var authInfoData = localStorage.authInfo
      if (authInfoData === undefined || authInfoData === null) {
        this.$router.replace({ name: "login" });
      } else {
        return JSON.parse(authInfoData);
      }
    },
    isPremium() {
      return this.getUser().authorities.includes(
        "ROLE_USER_PREMIUM"
      );
    },
    authenticate(loginData) {
      return axios.post("/api/auth", loginData)
    },
    register(registerDto) {
      return axios.post("/api/register", registerDto)
    },
    activate(token) {
      return axios.post("/api/register/activate/" + token);
    },
    getUser() {
      if (this._user == null) {
        var token = this.getAuthInfo()["authorization"];
        var parsedToken = this.parseJwt(token.substring(7, token.length - 1));
        console.log("parsing user");
        this._user = parsedToken;
      } else {
        console.log("not parsing user");
      }
      return this._user;
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    requestReset(username) {
      return axios.post("/api/user/password/reset?email=" + username);
    },
    changePassword(email, token, password) {
      return axios.post("/api/user/password", { 'email': email, 'token': token, 'password': password });
    },
    registerDevice(deviceLinkDto) {
      let info = this.getAuthInfo();
      return axios.post("/api/devicemapping", deviceLinkDto, { headers: { "Authorization": info.authorization } })
    },
    setAlias(deviceId, alias) {
      let info = this.getAuthInfo();
      return axios.put("/api/device/" + deviceId + "/alias", null, { params: { alias }, headers: { "Authorization": info.authorization } })
    },
    getDevices() {
      let info = this.getAuthInfo();
      return axios.get("/api/device", { headers: { "Authorization": info.authorization } });
    },
    getAlertsForDevice(deviceId) {
      let info = this.getAuthInfo();
      return axios.get("/api/device/" + deviceId + "/notification", { headers: { "Authorization": info.authorization } });
    },
    createAlertForDevice(deviceId, notification) {
      let info = this.getAuthInfo();
      return axios.post("/api/device/" + deviceId + "/notification", notification, { headers: { "Authorization": info.authorization } });
    },
    updateAlertForDevice(deviceId, notification) {
      let info = this.getAuthInfo();
      return axios.put("/api/device/" + deviceId + "/notification/" + notification.id, notification, { headers: { "Authorization": info.authorization } });
    },
    deleteAlertForDevice(deviceId, alertId) {
      let info = this.getAuthInfo();
      return axios.delete("/api/device/" + deviceId + "/notification/" + alertId, { headers: { "Authorization": info.authorization } });
    },
    updateSettings(deviceId, settings) {
      let info = this.getAuthInfo();
      return axios.put("/api/device/" + deviceId + "/settings", settings, { headers: { "Authorization": info.authorization } })
    },
    getSettings(deviceId) {
      let info = this.getAuthInfo();
      return axios.get("/api/device/" + deviceId + "/settings", { headers: { "Authorization": info.authorization } });
    },
    getMarkers(deviceId, timeFrame) {
      let info = this.getAuthInfo();
      return axios.get("/api/marker/" + deviceId + "/" + timeFrame, { headers: { "Authorization": info.authorization } });
    },
    createMarker(deviceId, markerDto) {
      let info = this.getAuthInfo();
      return axios.put("/api/marker/" + deviceId + "/", markerDto, { headers: { "Authorization": info.authorization } });
    },
    deleteMarker(deviceId, markerDto) {
      let info = this.getAuthInfo();
      return axios.delete("/api/marker/" + deviceId + "/", { data: markerDto, headers: { "Authorization": info.authorization } });
    },
    getUserSettings() {
      let info = this.getAuthInfo();
      return axios.get("/api/settings", { headers: { "Authorization": info.authorization } })
    },
    updateUserSettings(settingsDto) {
      let info = this.getAuthInfo();
      return axios.put("/api/settings", settingsDto, { headers: { "Authorization": info.authorization } })
    },
    clearLogin() {
      localStorage.removeItem.authInfo;
    }

  }
};
export default AjaxCalls;
