<template>
  <div class="wrapper">
    <div id="nav-tabs">
      <div class="md-layout">
        <div class="md-layout-item">
          <nav-tabs-card no-label>
            <template slot="content">
              <md-tabs
                md-sync-route
                :md-active-tab="activeTab"
                class="md-info"
                md-alignment="centered"
              >
                <md-tab id="vpd" md-label="Graph" md-icon="show_chart">
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <md-field>
                        <label for="timeFrame">Time frame</label>
                        <md-select
                          v-model="timeFrame"
                          name="timeFrame"
                          id="timeFrame"
                          @md-selected="updateTimeFrame()"
                        >
                          <md-option value="H1">Last hour</md-option>
                          <md-option value="D1">Last 24 hours</md-option>
                          <md-option value="D3">Last 3 days</md-option>
                          <md-option value="W1">One week</md-option>
                          <md-option v-if="isPremium()" value="W3"
                            >Three weeks</md-option
                          >
                          <md-option disabled v-if="!isPremium()" value="W3"
                            >Three weeks - premium only</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-display-1 liveMonitor">
                      <span>Live</span>
                      <md-icon v-bind:class="{ isLive: live }"
                        >fiber_manual_record</md-icon
                      >
                    </div>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <v-chart
                        ref="vpdChart"
                        @brush="onBrush"
                        @zr:mouseup="createNote"
                        @click="onMouseClick"
                        autoresize
                        :option="vpdChart"
                      />
                    </div>
                  </div>
                </md-tab>
                <md-tab
                  id="alerts"
                  md-label="Alerts"
                  md-icon="notification_important"
                >
                  <p>
                    <md-list class="md-double-line">
                      <md-list-item
                        v-for="(alert, alertIndex) in alerts"
                        :key="alert.id"
                        class="alert-high"
                      >
                        <md-icon>warning</md-icon>
                        <div class="md-list-alert-text">
                          <span>Measurement</span>
                          <span>{{ alert.measurement }}</span>
                        </div>
                        <div class="md-list-alert-text">
                          <span>Type</span>
                          <span>{{ alert.type }}</span>
                        </div>
                        <div class="md-list-alert-text">
                          <span>Threshold</span>
                          <span>{{ alert.threshold }}</span>
                        </div>
                        <div class="md-list-alert-text">
                          <span>Last notification</span>
                          <span>{{ alert.lastNotification }}</span>
                        </div>
                        <md-button
                          @click="editModal(alertIndex)"
                          class="md-primary md-just-icon"
                          ><md-icon>edit</md-icon></md-button
                        >
                      </md-list-item>
                      <md-divider></md-divider>
                    </md-list>
                    <md-button
                      @click="openModal"
                      class="md-primary md-just-icon md-round classic-modal"
                      ><md-icon>add</md-icon></md-button
                    >
                  </p>
                </md-tab>

                <md-tab id="settings" md-label="Settings" md-icon="build">
                  <md-field class="md-form-group">
                    <md-icon>videocam</md-icon>
                    <label>Video URL</label>
                    <md-input v-model="settings.vUrl"></md-input>
                  </md-field>
                  <md-field class="md-form-group">
                    <md-icon>badge</md-icon>
                    <label>Alias/Name</label>
                    <md-input v-model="settings.alias"></md-input>
                  </md-field>
                  <md-snackbar
                    :md-position="'center'"
                    :md-duration="2000"
                    :md-active.sync="showSavebar"
                    md-persistent
                  >
                    <span>Settings successfully saved</span>
                  </md-snackbar>
                  <md-button
                    @click="saveSettings()"
                    class="md-simple md-info md-lg"
                    >save</md-button
                  >
                </md-tab>
                <md-tab
                  v-if="settings.vUrl"
                  id="video"
                  md-label="webcam"
                  md-icon="videocam"
                >
                  <img :src="settings.vUrl" />
                </md-tab>
              </md-tabs>
            </template>
          </nav-tabs-card>
        </div>
      </div>
    </div>
    <modal v-if="alertModal" @close="closeModal">
      <template slot="header">
        <h4 class="modal-title">Alert</h4>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="closeModal"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout-item">
          <md-field>
            <label for="movie">Measurement</label>
            <md-select
              v-model="alertEdit.measurement"
              name="measurement"
              id="measurement"
            >
              <md-option value="humidity">Humidity</md-option>
              <md-option value="temperature">Temperature</md-option>
              <md-option value="pm10">PM10</md-option>
              <md-option value="pm2p5">PM2.5</md-option>
              <md-option value="co2">CO2</md-option>
              
            </md-select>
          </md-field>
        </div>

        <md-radio v-model="alertEdit.type" value="HIGH">Maximum</md-radio>
        <md-radio v-model="alertEdit.type" value="LOW">Minimum</md-radio>
        <md-radio v-model="alertEdit.type" value="RANGE">Range</md-radio>

        <md-field v-if="alertEdit.type != 'RANGE'">
          <label>Threshold value</label>
          <md-input v-model="alertEdit.threshold"></md-input>
        </md-field>

        <md-field v-if="alertEdit.type == 'RANGE'">
          <label>Target Value</label>
          <md-input v-model="alertEdit.value"></md-input>
        </md-field>

        <md-field v-if="alertEdit.type == 'RANGE'">
          <label>Maximum % deviation from value</label>
          <md-input v-model="alertEdit.threshold"></md-input>
        </md-field>
      </template>

      <template slot="footer">
        <md-button @click="saveAlert" class="md-primary md-simple"
          >Save</md-button
        >
        <md-button @click="deleteAlert" class="md-danger md-simple"
          >Delete alert</md-button
        >
        <md-button class="md-danger md-simple" @click="closeModal"
          >Close</md-button
        >
      </template>
    </modal>
    <modal v-if="showMarkerModal" @close="closeMarkerModal">
      <template slot="header">
        <h4 class="modal-title">Note</h4>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="closeMarkerModal"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout-item">
          <md-field>
            <label for="color">Color</label>
            <md-select v-model="currentMarker.color" name="color" id="color">
              <md-option value="#F72C25">Red</md-option>
              <md-option value="#A9E5BB">Green</md-option>
              <md-option value="#086788">Blue</md-option>
              <md-option value="#F7B32B">Orange</md-option>
              <md-option value="#131200">Black</md-option>
            </md-select>
          </md-field>
        </div>

        <md-field>
          <label>Note:</label>
          <md-input v-model="currentMarker.note"></md-input>
        </md-field>
      </template>

      <template slot="footer">
        <md-button @click="saveMarker" class="md-primary md-simple"
          >Save</md-button
        >
        <md-button @click="removeMarker" class="md-danger md-simple"
          >Delete note</md-button
        >
        <md-button class="md-danger md-simple" @click="closeMarkerModal"
          >Close</md-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import { NavTabsCard } from "@/components";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";

import {
  VisualMapComponent,
  DataZoomComponent,
  LegendComponent,
  MarkAreaComponent,
  MarkLineComponent,
  AxisPointerComponent,
  ToolboxComponent,
  BrushComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  VisualMapComponent,
  DataZoomComponent,
  LegendComponent,
  MarkAreaComponent,
  MarkLineComponent,
  AxisPointerComponent,
  ToolboxComponent,
  BrushComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
]);

import { AjaxCalls } from "@/mixins";
import { Modal } from "@/components";

export default {
  components: {
    NavTabsCard,
    VChart,
    Modal,
  },
  props: {
    deviceIdProp: String,
    deviceSettings: Object,
  },
  mixins: [AjaxCalls],
  computed: {
    settings() {
      if (this.deviceSettings && this.deviceSettings.vUrl) {
        this.showVido = true;
      }
      return this.deviceSettings;
    },
  },
  mounted() {},
  beforeMount() {
    setInterval(
      function () {
        if (this.live && this.timeFrame != null) {
          this.updateData();
        }
      }.bind(this),
      60000
    );
    this.getUserSettings().then((resp) => {
      this.userSettings = resp.data;
      if (this.userSettings.imperial) {
        this.vpdChart.yAxis[1].name = "°F";
      }
    });

    //solves scaling issue for page somehow
    setTimeout(
      function () {
        this.timeFrame = "D1";
      }.bind(this),
      200
    );
    this.alerts = [
      {
        id: "12uhk12h3k1u2h3",
        measurement: "temperature",
        threshold: 25,
        type: "HIGH",
        lastNotification: "25-04-2021 20:44",
      },
    ];
    this.getAlertsForDevice(this.deviceId).then((result) => {
      this.alerts = result.data;
    });

    this.vpdChart = {
      title: {
        text: "Air Quality",
      },
      legend: {
        show: true,
        left: "center",
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        position: function (pos, params, el, elRect, size) {
          var obj = { top: 10 };
          obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
        valueFormatter: function (value) {
          return value.toFixed(2); // params.data.toFixed(2)
        },
      },
      brush: {
        xAxisIndex: "all",
        brushLink: "all",
        outOfBrush: {
          colorAlpha: 0.1,
        },
      },
      toolbox: {
        orient: "vertical",
        left: "2%",
        top: "10%",
        feature: {
          dataZoom: {
            yAxisIndex: false,
          },
          brush: {
            type: ["lineX"],
            title: {
              lineX: "Add note",
            },
          },
          saveAsImage: {
            title: "Save image",
            icon: "path://M 1.4,4.663 1.5,59.437 62.6,59.237 62.4,4.763 Z M 62,5.2631502 V 34.480751 l -14.847698,-13.9461 c -0.199204,-0.1875 -0.458,-0.289002 -0.742203,-0.269501 -0.273399,0.0156 -0.528297,0.142599 -0.705998,0.352499 L 28.426701,42.842 15.4111,30.37155 c -0.3984,-0.327099 -0.9863,-0.296799 -1.3496,0.0752 L 2,44.707 V 5.2631502 Z M 2,58.736851 V 45.67025 L 14.8525,30.9 27.9209,43.213348 c 0.4228,0.347702 1.044899,0.2901 1.3974,-0.127899 L 46.5508,20.613 62,35.324 v 23.412851 z ",
          },
          myTool1: {
            show: true,
            title: "Show alerts",
            icon: "path://M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z",
            onclick: this.drawAlerts,
          },
        },
        emphasis: {
          iconStyle: {
            textPosition: "bottom",
            textBackgroundColor: "white",
          },
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: false,
        },
        axisPointer: {
          show: true,
          snap: true,
        },
      },
      yAxis: [
        {
          type: "value",
          name: "°C",
          scale: true,
          splitLine: {
            show: true,
          },
        },
        {
          type: "value",
          name: "ppm",
          scale: true,
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          name: "%",
          scale: true,
          offset: 35,
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          name: "µg/m3",
          offset: 80,
          scale: true,
          splitLine: {
            show: false,
          },
        },
      ],
      dataZoom: [
        {},
        {
          type: "inside",
        },
      ],
      series: [
        {
          name: "Temperature",
          type: "line",
          smooth: true,
          yAxisIndex: 0,
          markArea: {
            data: [],
          },
          markLine: {
            silent: false,
            symbol: "none",
            data: [],
          },
          data: [],
        },
        {
          name: "CO2",
          type: "line",
          yAxisIndex: 1,
          smooth: true,
          markLine: {
            silent: false,
            symbol: "none",
            data: [],
          },
          data: [],
        },
        {
          name: "Humidity",
          type: "line",
          yAxisIndex: 2,
          smooth: true,
          markLine: {
            silent: false,
            symbol: "none",
            data: [],
          },
          data: [],
        },
        {
          name: "PM2.5",
          type: "line",
          yAxisIndex: 3,
          smooth: true,
          markLine: {
            silent: false,
            symbol: "none",
            data: [],
          },
          data: [],
        },
        {
          name: "PM10",
          type: "line",
          yAxisIndex: 3,
          smooth: true,
          markLine: {
            silent: false,
            symbol: "none",
            data: [],
          },
          data: [],
        },
      ],
    };
  },
  methods: {
    initOptions() {},
    onMouseClick(event) {
      if (event.componentType == "markArea") {
        let area = event.data;
        this.currentMarker.color = area.itemStyle.color;
        this.currentMarker.note = area.name;
        this.markerModal(area.coord[1][0], area.coord[0][0]);
      }
    },
    drawAlerts() {
      if (!this.showAlerts) {
        this.alerts.forEach((element, i) => {
          if (element.type === "RANGE") {
            var upper = {
              yAxis: element.value * (1 + element.threshold / 100),
              // symbol:
              //   "path://M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z",
              // symbolRotate: 360,
              // symbolOffset: [-35, 0],
              type: "max",
              lineStyle: {
                color: "#00FF00",
                type: "line",
                width: 2,
              },
            };
            var lower = {
              yAxis: element.value * (1 - (element.threshold / 100)),
             // symbol:
              //  "path://M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z",
             // symbolRotate: 180,
              //symbolOffset: [35, 0],
              type: "min",
              lineStyle: {
                color: "#00FF00",
                type: "line",
                width: 2,
              },
            };
            if (element.measurement === "temperature") {
              this.vpdChart.series[1].markLine.data.push(upper,lower);
            }
            if (element.measurement === "vpd") {
              this.vpdChart.series[0].markLine.data.push(upper,lower);
            }
            if (element.measurement === "leafTemperature") {
              this.vpdChart.series[2].markLine.data.push(upper,lower);
            }
            if (element.measurement === "humidity") {
              this.vpdChart.series[3].markLine.data.push(upper,lower);
            }
            if (element.measurement === "co2") {
              this.vpdChart.series[4].markLine.data.push(upper,lower);
            }
          } else {
            var alertLine = {};
            if (element.type === "HIGH") {
              alertLine = {
                yAxis: element.threshold,
                // symbol:
                //   "path://M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z",
                // symbolRotate: 360,
                // symbolOffset: [-35, 0],
                type: "max",
                lineStyle: {
                  color: "#FF0000",
                  type: "line",
                  width: 2,
                },
              };
            }
            if (element.type === "LOW") {
              alertLine = {
                yAxis: element.threshold,
                // symbol:
                //   "path://M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z",
                // symbolRotate: 180,
                // symbolOffset: [35, 0],
                type: "min",
                lineStyle: {
                  color: "#0000FF",
                  type: "line",
                  width: 2,
                },
              };
            }
            if (element.measurement === "temperature") {
              this.vpdChart.series[1].markLine.data.push(alertLine);
            }
            if (element.measurement === "vpd") {
              this.vpdChart.series[0].markLine.data.push(alertLine);
            }
            if (element.measurement === "leafTemperature") {
              this.vpdChart.series[2].markLine.data.push(alertLine);
            }
            if (element.measurement === "humidity") {
              this.vpdChart.series[3].markLine.data.push(alertLine);
            }
            if (element.measurement === "co2") {
              this.vpdChart.series[4].markLine.data.push(alertLine);
            }
          }

          this.showAlerts = true;
        });
      } else {
        for (const serie of this.vpdChart.series) {
          console.log(serie);
          serie.markLine.data = [];
        }
        this.showAlerts = false;
      }
      this.$refs.vpdChart.setOption(this.vpdChart);
    },
    onBrush(params) {
      if (params.areas.length > 0) {
        this.brushCoords = params.areas[0].coordRange;
      }
    },
    clearSelection() {
      this.$refs.vpdChart.dispatchAction({
        type: "brush",
        command: "clear",
        areas: [],
      });
      this.$refs.vpdChart.dispatchAction({
        type: "takeGlobalCursor",
        key: "brush",
        brushOption: {
          brushType: false,
        },
      });
    },
    createNote(event) {
      if (this.brushCoords.length > 0) {
        this.markerModal(this.brushCoords[0], this.brushCoords[1]);
        this.brushCoords = [];
        setTimeout(this.clearSelection, 1);
      }
    },
    mapTemperature(item) {
      let temp = this.userSettings.imperial
        ? (item.measurements.temperature.value * 9) / 5 + 32
        : item.measurements.temperature.value;
      return [item.measurements.temperature.timestamp * 1000, temp];
    },
    updateData() {
      if (this.timeFrame != null) {
        this.getMeasurements(this.deviceId, this.timeFrame)
          .then((response) => {
            this.vpdChart.series[0].data = response.data.map(
              this.mapTemperature.bind(this)
            );
            this.vpdChart.series[2].data = response.data.map(function (item) {
              return [
                item.measurements.humidity.timestamp * 1000,
                item.measurements.humidity.value,
              ];
            });
            this.vpdChart.series[3].data = response.data.map(function (item) {
              return [
                item.measurements.pm2p5.timestamp * 1000,
                item.measurements.pm2p5.value,
              ];
            });
            this.vpdChart.series[4].data = response.data.map(function (item) {
                return [
                  item.measurements.pm10.timestamp * 1000,
                  item.measurements.pm10.value,
                ];
              });
            if (this.hasCo2(response.data)) {
              this.vpdChart.series[1].data = response.data.map(function (item) {
                return [
                  item.measurements.co2.timestamp * 1000,
                  item.measurements.co2.value,
                ];
              });
            }
            this.updateMarkers();
          })
          .catch((error) => {
            debugger;
            let status = error.response.status;
            console.log(error);
            if (status === 401) {
              this.clearLogin();
              this.$router.replace({ name: "login" });
            }
          });
      }
    },
    updateMarkers() {
      this.getMarkers(this.deviceId, this.timeFrame).then((result) => {
        this.markers = result.data;
        result.data.forEach((element, i) => {
          var marker = [
            {
              name: element.note,
              xAxis: element.to * 1000,
              itemStyle: {
                color: element.color,
              },
            },
            {
              name: "Morning Peak end",
              xAxis: element.from * 1000,
            },
          ];
          this.vpdChart.series[0].markArea.data[i] = marker;
          this.$refs.vpdChart.setOption(this.vpdChart);
        });
      });
    },
    updateTimeFrame() {
      if (this.timeFrame === "H1" || this.timeFrame === "D1") {
        this.live = true;
      } else {
        this.live = false;
      }
      this.updateData();
    },
    openModal() {
      this.alertModal = true;
    },
    hasCo2(data) {
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if ("co2" in data[i].measurements) {
            return true;
          }
        }
      }
      return false;
    },
    editModal(index) {
      this.alertEdit = JSON.parse(JSON.stringify(this.alerts[index]));
      this.alertModal = true;
    },
    markerModal(from, to) {
      this.showMarkerModal = true;
      this.currentMarker.from = Math.round(from / 1000);
      this.currentMarker.to = Math.round(to / 1000);
    },
    saveMarker() {
      this.createMarker(this.deviceId, this.currentMarker)
        .then((result) => {
          this.updateMarkers();
          this.closeMarkerModal();
        })
        .catch((error) => {
          alert(error);
          this.clearSelection;
        });
    },
    removeMarker() {
      this.deleteMarker(this.deviceId, this.currentMarker)
        .then((result) => {
          this.closeMarkerModal();
          this.updateMarkers();
        })
        .catch((error) => {
          alert(error);
          this.clearSelection;
        });
    },
    saveAlert() {
      if (this.alertEdit.id == null) {
        this.createAlertForDevice(this.deviceId, this.alertEdit).then(
          (result) => {
            this.alerts.push(result.data);
            this.alertModal = false;
          }
        );
      } else {
        this.updateAlertForDevice(this.deviceId, this.alertEdit).then(
          (result) => {
            debugger;
            const index = this.alerts.findIndex(
              (element) => element.id === result.data.id
            );
            this.alerts[index] = result.data;
            this.alertModal = false;
          }
        );
      }
    },
    deleteAlert() {
      if (confirm("Are you sure you want to delete this alert?")) {
        this.deleteAlertForDevice(this.deviceId, this.alertEdit.id);
      }
      this.closeModal();
    },
    closeModal() {
      this.alertModal = false;
      this.alertEdit = {};
    },
    closeMarkerModal() {
      this.showMarkerModal = false;
      this.currentMarker = {};
    },
    saveSettings() {
      this.updateSettings(this.deviceId, this.settings).then((result) => {
        this.showSavebar = true;
      });
    },
  },
  data() {
    return {
      deviceId: this.deviceIdProp,
      vpdChart: {},
      userSettings: {},
      alerts: [],
      alertModal: false,
      alertEdit: {},
      showAlerts: false,
      showMarkerModal: false,
      timeFrame: null,
      live: true,
      measurements: [],
      showSavebar: false,
      activeTab: null,
      forceUpdate: 0,
      brushCoords: [],
      currentMarker: {},
      markers: [],
    };
  },
};
</script>

<style lang="scss">
@media (min-width: 576px) {
  .md-toolbar-row,
  .section .container,
  .footer .container {
    max-width: 960px;
  }

  .md-list.nav-mobile-menu {
    display: none;
  }

  .hidden-lg {
    display: none !important;
  }
  .echarts {
    width: 100%;
    height: 500px;
    box-sizing: border-box;
  }
  .md-tabs-content {
    width: 500px;
  }
}

@media (min-width: 768px) {
  .md-toolbar-row,
  .section .container,
  .footer .container {
    max-width: 960px;
  }

  .md-list.nav-mobile-menu {
    display: none;
  }

  .hidden-lg {
    display: none !important;
  }
  .echarts {
    width: 100%;
    height: 500px;
    box-sizing: border-box;
  }
  .md-tabs-content {
    width: 645px;
  }
}

@media (min-width: 992px) {
  .md-toolbar-row,
  .section .container,
  .footer .container {
    max-width: 960px;
  }

  .md-list.nav-mobile-menu {
    display: none;
  }

  .hidden-lg {
    display: none !important;
  }
  .echarts {
    width: 100%;
    height: 500px;
    box-sizing: border-box;
  }
  .md-tabs-content {
    width: 867px;
  }
}

@media (min-width: 1200px) {
  .md-toolbar-row,
  .section .container,
  .footer .container {
    max-width: 1132px !important;
  }
  .echarts {
    width: 100%;
    height: 900px;
    box-sizing: border-box;
  }
  .md-tabs-content {
    width: 1072px;
  }
}

@media (min-width: 1449px) {
  .md-toolbar-row,
  .section .container,
  .footer .container {
    max-width: 1360px !important;
  }
  .echarts {
    width: 100%;
    height: 700px;
    box-sizing: border-box;
  }
  .md-tabs-content {
    width: 1300px;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}
.liveMonitor {
  text-align: right;
  .isLive {
    color: red !important;
    animation: blinker 2s linear infinite !important;
  }
}
.md-list-item-container {
  color: whitesmoke;
}
.md-list-alert-text {
  -webkit-box-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  overflow: hidden;
  line-height: 1.25em;
  white-space: nowrap;
  color: white;
}

.md-list-alert-text :nth-child(2) {
  font-size: 14px;
  color: #ffffffe1;
}

.alert-high {
  background-color: #e0845a;
}
.alert-low {
  background-color: #7cd0b0;
}
.alert-custom {
  background-color: #9b8c59;
}
</style>