<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-60 md-small-size-90 md-xsmall-size-100 md-medium-size-80 mx-auto"
          >
            <registration-card header-color="info">
              <h4 slot="title" class="card-title">Your account has been activated</h4>
              <p slot="description" class="description">You can log in now</p>
            </registration-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RegistrationCard } from "@/components";
import { AjaxCalls } from "@/mixins";

export default {
  components: {
    RegistrationCard
  },
  bodyClass: "activate-page",
  data() {
    return {
      token: this.$route.params.token,
    };
  },
      mounted(){
      this.activate(this.token).then((response)=>{
          setTimeout(()=>{this.$router.replace({ name: "login"})},3000);
      })        
    },
  mixins: [
    AjaxCalls
    ],

  methods: {

  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    }
  }
};
</script>

<style lang="css">
  .md-card-content .md-field .md-error {
    bottom: -5px;
  }
</style>
