<template>
  <div class="wrapper">
    <div class="page-header header-filter" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="brand">
            <h1>{{ name }}</h1>
            <h3>Device detail</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="main main-raised">
      <div class="section section-basic">
        <div class="container">
          <div class="md-layout">
            <device-detail
              :deviceSettings="this.settings"
              :deviceIdProp="this.$route.params.deviceId"
            ></device-detail>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DeviceDetail from "./components/DeviceDetailSection";
import { AjaxCalls } from "@/mixins";

export default {
  bodyClass: "detail-page",
  components: {
    DeviceDetail
  },
  mixins: [AjaxCalls],
  data() {
    return {};
  },
  created() {
    this.getSettings(this.$route.params.deviceId).then((result) => {
      this.settings = result.data;
    });
  },
  methods: {},
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg")
    },
    deviceId:String
  },
  data() {
    return {
      settings:{}
    };
},
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    name() {
        return this.settings.alias ? this.settings.alias : this.$route.params.deviceId;
    }
  }
};
</script>
<style lang="scss" scoped>
.brand {
  color: #ffffff;
}
.page-header {
  height: 30vh;
}
</style>