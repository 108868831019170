<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item
              md-size-33
              md-small-size-66
              md-xsmall-size-100
              md-medium-size-40
              mx-auto
            "
          >
            <login-card header-color="info">
              <h4 slot="title" class="card-title">Login</h4>
              <h4 class="status-message" slot="statusMessage">
                {{ statusMessage }}
              </h4>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>face</md-icon>
                <label>username...</label>
                <md-input
                  @keyup.enter="login()"
                  v-model="input.username"
                ></md-input>
              </md-field>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>lock_outline</md-icon>
                <label>Password...</label>
                <md-input
                  type="password"
                  @keyup.enter="login()"
                  v-model="input.password"
                ></md-input>
              </md-field>
              <md-button
                @click="login()"
                slot="footer"
                class="md-simple md-primary md-lg"
                >login</md-button
              >
              <md-button
                @click="forgot()"
                slot="footer"
                class="md-simple md-info md-lg"
                >reset password</md-button
              >
              <md-button
                @click="registration()"
                slot="footer"
                class="md-simple md-info md-lg"
                >register</md-button
              >
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "@/components";
import { AjaxCalls } from "@/mixins";
import "jwt-decode/build/jwt-decode.min.js";

export default {
  components: {
    LoginCard,
  },
  bodyClass: "login-page",
  data() {
    return {
      input: {
        username: null,
        password: null,
      },
      statusMessage: this.$route.params.alertStatus,
      returnUrl: this.$route.query.r,
    };
  },
  mixins: [AjaxCalls],
  methods: {
    login() {
      if (this.input.username != "" && this.input.password != "") {
        let self = this;
        this.authenticate({
          username: this.input.username,
          password: this.input.password,
        })
          .then((resp) => {
            self.$emit("authenticated", true);
            let jwt = jwt_decode(resp.headers.authorization);
            console.log(jwt);
            localStorage.authInfo = JSON.stringify({
              authorization: resp.headers.authorization,
              userId: jwt.userid,
            });
            if (this.returnUrl) {
              let url = atob(this.returnUrl);
              //avoid url redirection attacks
              if(url.startsWith(process.env.VUE_APP_ROOT)){
                window.location.replace(url);
              } else {
                self.$router.replace({ name: "overview" });
              }
              
            } else {
              self.$router.replace({ name: "overview" });
            }
          })
          .catch((error) => {
            let status = error.response.status;
            console.log(error);
            if (status === 401) {
              this.statusMessage = "Wrong username/password please try again";
            } else {
              this.statusMessage =
                "Something went wrong please try again in a minute";
            }
            console.log("The username and / or password is incorrect");
          });
      } else {
        console.log("A username and password must be present");
      }
    },
    registration() {
      this.$router.replace({ name: "registration" });
    },
    forgot() {
      if (this.input.username.length > 0) {
        this.requestReset(this.input.username);
        this.statusMessage =
          "If your account is found a reset email will be sent";
      } else {
        this.statusMessage = "Please enter username";
      }
    },
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style  lang="css" scoped>
.md-layout-item.md-size-33 {
  min-width: 42.333%;
}
.login-page .page-header {
  height: 100vh;
}
</style>
