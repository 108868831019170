<template>
  <div>
    <md-card>
      <div class="md-card-content">
        <md-toolbar class="md-primary device-name">
          <md-button
            @click="enableEditTitle"
            style="position: fixed"
            class="md-just-icon md-simple md-danger edit-title"
          >
            <md-icon>edit</md-icon>
          </md-button>
          <input
            @keyup.enter="disableEditTitle"
            @blur="disableEditTitle"
            class="md-field"
            ref="alias"
            v-if="editTitle"
            v-model="device.alias"
          />
          <h3
            v-on:click="navigateToDetail"
            v-if="!editTitle"
            class="md-title"
            style="flex: 1 1 auto"
          >
            {{ device.alias }}
          </h3>
          <md-button v-on:click="navigateToDetail" class="md-primary md-lg">
            view graph
            <md-icon>assessment</md-icon>
          </md-button>
        </md-toolbar>
        <div class="md-layout">
          <div class="md-layout-item md-size-15"></div>
        </div>
        <div
          :style="
            device.lastMeasurements ? '' : 'filter: grayscale(1) blur(10px);'
          "
          class="md-layout md-gutter"
        >
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">Temperature</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">
                {{
                  device.lastMeasurements
                    ? device.lastMeasurements.temperature.value.toFixed(2)
                    : "1.5"
                }}
                &#176;C
              </h2>
            </div>
          </div>
          
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">Humidity</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">
                {{
                  device.lastMeasurements
                    ? device.lastMeasurements.humidity.value.toFixed(2)
                    : 60
                }}%
              </h2>
            </div>
          </div>
          <div
            class="md-layout-item md-layout"
          >
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">CO2</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">
                {{
                  device.lastMeasurements
                    ? device.lastMeasurements.co2.value.toFixed(2)
                    : 400
                }}
                ppm
              </h2>
            </div>
          </div>
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">PM10</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">
                {{
                  device.lastMeasurements
                    ? device.lastMeasurements.pm10.value.toFixed(3)
                    : 20.33
                }}µg/m3
              </h2>
            </div>
          </div>
          
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">PM2.5</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">
                {{
                  device.lastMeasurements
                    ? device.lastMeasurements.pm2p5.value.toFixed(3 )
                    : 22.45
                }}µg/m3
              </h2>
            </div>
          </div>
        </div>
      </div>
    </md-card>
    <h2 class="md-display-2 no-data-yet" v-if="!device.lastMeasurements">
      No data yet for device!
    </h2>
  </div>
</template>

<script>
import { AjaxCalls } from "@/mixins";
export default {
  name: "device-card",
  beforeMount() {
    if (this.device.alias == null) {
      this.device.alias = this.device.deviceId;
    }
  },
  mixins: [AjaxCalls],
  methods: {
    navigateToDetail() {
      this.$router.push({
        name: "detail",
        params: { deviceId: this.device.deviceId },
      });
    },
    enableEditTitle() {
      this.editTitle = true;
      this.$nextTick(() => this.$refs.alias.select());
    },
    disableEditTitle() {
      this.editTitle = false;
      this.setAlias(this.device.deviceId, this.device.alias);
      console.log("fire update alias for device");
    },
  },
  data() {
    return {
      editTitle: false,
    };
  },
  props: {
    device: {
      type: Object,
    },
  },
  computed: {
    deviceCardModifier() {
      if (this.device.status == "ERROR") {
        return "md-card-header-red";
      } else {
        return "md-card-header-green";
      }
    },
  },
};
</script>    
<style lang="scss" scoped>
.md-toolbar {
  .md-title {
    font-size: 30px;
  }
  .md-field {
    flex: 1 1 auto;
    width: 50%;
    margin-left: 20px;
    height: 36px;
    padding: 7px 0;
    font-size: 30px !important;
    font-weight: 300;
    -webkit-text-fill-color: #ffffff !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 32px;
    padding: 0;
    display: block;
    border: none;
    background: none;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: font-size, padding-top, color;
    font-family: inherit;
    line-height: 32px;
  }
}
.device-name {
  &:hover > .edit-title {
    display: block;
  }
}
.edit-title {
  display: none;
  position: fixed;
  color: white;
  left: 0px;
}
.no-data-yet {
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 47%;
  z-index: 1;
}
</style>
    