import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import MaterialKit from "./plugins/material-kit";
import moment from 'moment';
import ECharts from 'vue-echarts'
import vueCountryRegionSelect from 'vue-country-region-select';

Vue.config.productionTip = false;

Vue.use(MaterialKit);
Vue.prototype.moment = moment

Vue.component('v-chart', ECharts)


Vue.use(vueCountryRegionSelect);

//workaround for bug in old version of vue material possibly can be removed in the future
Vue.component('router-link', Vue.options.components.RouterLink);
Vue.component('router-view', Vue.options.components.RouterView);

const NavbarStore = {
  showNavbar: false
};

Vue.mixin({
  data() {
    return {
      NavbarStore
    };
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
