<template>
  <div id="material-kit">
      <router-view name="header" />
        <router-view @authenticated="setAuthenticated" />
      <router-view name="footer" />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      authenticated: false,
    };
  },
  beforeMount() {
    var authData = localStorage.authInfo;
    console.log(authData);
    if (authData !== null && authData !== undefined) {
      console.log("logged in");
      this.authenticated = true;
    } else if (
      !this.authenticated &&
      !(
        this.$router.currentRoute.name === "forgot" ||
        this.$router.currentRoute.name === "activate" ||
        this.$router.currentRoute.name === "login"
      )
    ) {
      localStorage.removeItem.authInfo;
      this.$router.replace({ name: "login", query: {r: btoa(window.location)} });      
    }
  },
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
    },
  },
};
</script>