<template>
  <div class="wrapper">
    <div class="page-header header-filter" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="brand">
            <h1 class="md-display-4">Devices</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="main main-raised">
      <div class="container">
        <div class="md-layout">
          <template v-for="device in devices">
            <device-card :key="device.id" :device="device"></device-card>
          </template>
          <device-card-placeholder></device-card-placeholder>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DeviceCard } from "@/components";
import { DeviceCardPlaceholder } from "@/components";
import { AjaxCalls } from "@/mixins";

export default {
  bodyClass: "overview-page",
  components: {
    DeviceCard,
    DeviceCardPlaceholder,
  },
  mounted() {
    this.getDevices()
      .then((resp) => {
        console.log(resp);
        this.devices = resp.data;
      })
      .catch((error) => {
        let status = error.response.status;
        console.log(error);
        if (status === 401) {
          this.clearLogin();
          this.$router.replace({ name: "login" });
        }
      });
  },
  data() {
    return {
      devices: [],
    };
  },
  mixins: [AjaxCalls],
  methods: {},
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg"),
    },
    placeholder: {
      type: String,
      default: require("@/assets/img/measurement_placeholder.png"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.brand {
  .md-display-4 {
    // color: $grey-400;
  }
}
.page-header {
  height: 30vh;
}
.section {
  .container {
    max-width: 2000px;
  }
}
</style>