<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item
              md-size-60
              md-small-size-90
              md-xsmall-size-100
              md-medium-size-80
              mx-auto
            "
          >
            <registration-card header-color="info">
              <h4 slot="title" class="card-title">Register device</h4>
              <p slot="description" class="description">
                Scan QR Code of device
              </p>

              <qrcode-stream
                slot="inputs"
                :camera="camera"
                @decode="onDecode"
                @init="onInit"
                :track="paintBoundingBox"
              >
                <div v-if="validationSuccess" class="validation-success">
                  Device found
                </div>

                <div v-if="validationFailure" class="validation-failure">
                  This is NOT a valid device!
                </div>

                <div v-if="validationPending" class="validation-pending">
                  Finding device...
                </div>
              </qrcode-stream>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>badge</md-icon>
                <label>Device name (optional)</label>
                <md-input v-model="input.name"></md-input>
              </md-field>

              <md-button
                @click="turnCameraOn()"
                slot="footer"
                class="md-simple md-success md-lg"
                >register device</md-button
              >
            </registration-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RegistrationCard } from "@/components";
import { AjaxCalls } from "@/mixins";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: {
    RegistrationCard,
    QrcodeStream,
  },
  bodyClass: "register-device-page",
  data() {
    return {
      input: {},
      isValid: undefined,
      camera: "auto",
      result: null,
    };
  },
  mixins: [AjaxCalls],
  beforeMount () {
     if(this.$route.query.c && this.$route.query.d){
       this.register(this.$route.query.d,this.$route.query.c);
     }
  },
  methods: {
    onInit(promise) {
      //promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();

      let urlParams = new URL(content).searchParams;
      this.isValid = urlParams.has("d") && urlParams.has("c");
      this.register(urlParams.get("d"),urlParams.get("c"))

      // some more delay, so users have time to read the message
      await this.timeout(2000);
      if (!this.isValid) {
        this.turnCameraOn();
        this.result = null;
        this.isValid = undefined;
      }
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#00FF00";
        ctx.strokeRect(x, y, width, height);
      }
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    register(deviceId, securityCode) {
      let authInfo = this.getAuthInfo();
      this.registerDevice({
        deviceId: deviceId,
        securityCode: securityCode,
        name: this.input.name,
      })
        .then((response) => {
          this.$router.replace({ name: "detail", params: {deviceId: deviceId} });
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 400) {
            alert(
              "Invalid QR code try rescanning"
            );
            this.isValid = false;
          } else if (status === 409) {
            this.isValid = false;
            this.$router.replace({ name: "detail", params: {deviceId: deviceId} });
          } else {
            alert("A server error occured, try again later");
            this.isValid = false;
          }
        });
    },
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },

    validationSuccess() {
      return this.isValid === true;
    },

    validationFailure() {
      return this.isValid === false;
    },
  },
};
</script>

<style scoped lang="css">
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>
