<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-60 md-small-size-90 md-xsmall-size-100 md-medium-size-80 mx-auto"
          >
            <registration-card header-color="info">
              <h4 slot="title" class="card-title">Register</h4>
              <p slot="description" class="description">Register</p>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>face</md-icon>
                <label>name</label>
                <md-input  v-model="input.name"></md-input>
              </md-field>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>mail</md-icon>
                <label>E-mail</label>
                <md-input  v-model="input.email"></md-input>
              </md-field>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>lock_outline</md-icon>
                <label>Password...</label>
                <md-input type="password" v-model="input.password"></md-input>
              </md-field>
              <md-button @click="registerUser()" :disabled="busy"  slot="footer" class="md-simple md-success md-lg">register</md-button>
            </registration-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RegistrationCard } from "@/components";
import { AjaxCalls } from "@/mixins";

export default {
  components: {
    RegistrationCard
  },
  bodyClass: "login-page",
  data() {
    return {
      input: {
        username: null,
        password: null
      },
      busy: false
    };
  },
  mixins: [AjaxCalls],
  methods: {
    registerUser() {
       this.busy = true;
        this.register({"name" : this.input.name, "email" : this.input.email, "password":this.input.password})
        .then((response) => {
          this.$router.replace({ name: "login" , params:{alertStatus: "Please activate your account by following instructions in email before logging in"}});
        }).catch((error)=>{
          console.log(error);
          this.busy = false;
          alert(error);
        })
    }
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg1.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    }
  }
};
</script>

<style lang="css"></style>
