<template>
  <div>
    <md-card
      style="filter: grayscale(1) blur(10px); user-select: none; /* supported by Chrome and Opera */ -webkit-user-select: none; /* Safari */ -khtml-user-select: none; /* Konqueror HTML */ -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer/Edge */"
    >
      <div class="md-card-content">
        <md-toolbar :style="deviceName ? 'filter: none;' : ''" class="md-primary">
          <h3 class="md-title" style="flex: 1">{{deviceName ? deviceName : "Grow room 1"}}</h3>
          <md-button disabled class="md-primary md-lg">
            settings
            <md-icon>build</md-icon>
          </md-button>
          <md-button disabled class="md-primary md-lg">
            view graph
            <md-icon>assessment</md-icon>
          </md-button>
        </md-toolbar>
        <div class="md-layout">
          <div class="md-layout-item md-size-15"></div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">VPD</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">1.32 Kpa</h2>
            </div>
          </div>
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">Leaf temp</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">22.34&#176;C</h2>
            </div>
          </div>
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">Humidity</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">36%</h2>
            </div>
          </div>
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">Pressure</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">10032 Hpa</h2>
            </div>
          </div>
          <div class="md-layout-item md-layout">
            <div class="md-layout-item md-size-100">
              <h4 class="md-display-1">Ambient temp</h4>
            </div>
            <div class="md-layout-item md-size-100">
              <h2 class="md-display-2">25.36&#176;C</h2>
            </div>
          </div>
        </div>
      </div>
    </md-card>
    <md-button v-if="!deviceName" class="md-primary md-lg add-device-button" @click="navigateToRegisterDevice">
      register device
      <md-icon>add</md-icon>
    </md-button>
    
  </div>
</template>

<script>
export default {
  name: "device-card-placeholder",
  methods: {
    navigateToRegisterDevice() {
      this.$router.push({
        name: "registerDevice"
      });
    }
  },
  props: {
    deviceName:""
  },
  computed: {}
};
</script>    
<style lang="scss" scoped>
.md-toolbar {
  .md-title {
    font-size: 30px;
  }
}
.add-device-button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 50%;
  //transform: translateY(-50%);
  z-index: 1;
}

</style>
    